import MainRouter from "./Routes/MainRouter/MainRouter";

function App() {
  return (
    <>
      <MainRouter></MainRouter>
    </>
  );
}

export default App;
