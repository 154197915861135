import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
const MainPage = lazy(()=>import("../../Pages/MainPage/MainPage"));
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";     
//core
import "primereact/resources/primereact.min.css";                                       
const MainRouter = () => {
  return (
    <main>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<div/>}>
                    <MainPage></MainPage>
              </Suspense>
            }
          />
        </Routes>
      </Router>
    </main>
  );
};
export default MainRouter;
